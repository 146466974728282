<template>
  <svg
    class="inline-block self-center overflow-hidden fill-current stroke-current stroke-0"
    :class="[classSize]"
    aria-hidden="true"
  >
    <use :xlink:href="`${symbolDefs}#icon-${name}`" :fill="color" :stroke="color" />
  </svg>
</template>

<script setup lang="ts">
import symbolDefs from '~/assets/images/icons/symbol-defs.svg'

export type Icon =
  | 'airplane'
  | 'arrow-left'
  | 'arrow-right'
  | 'check-rounded'
  | 'chevron-down'
  | 'clock'
  | 'close'
  | 'commute'
  | 'facebook'
  | 'globe'
  | 'instagram'
  | 'link'
  | 'linked-in'
  | 'map-pin'
  | 'twitter'
  | 'arrow-down'
  | 'check'
  | 'youtube'
  | 'search'

export interface Props {
  color?: string
  name: Icon
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}

const props = withDefaults(defineProps<Props>(), {
  color: 'black',
  size: 'md'
})

const classSize = computed(() => {
  let classList
  switch (props.size) {
    case '2xs':
      classList = 'w-3 h-3'
      break
    case 'xs':
      classList = 'w-4 h-4'
      break
    case 'sm':
      classList = 'w-5 h-5'
      break
    case 'lg':
      classList = 'w-8 h-8'
      break
    case 'xl':
      classList = 'w-10 h-10'
      break
    case '2xl':
      classList = 'w-16 h-16'
      break
    default:
      classList = 'w-6 h-6'
  }
  return classList
})
</script>
